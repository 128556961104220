import React, { useState, useRef, useEffect } from 'react';

const Fader = () => {
  const [fadeProp, setFadeProp] = useState({
    animate: true,
  });

  const fadeIn = useRef(() => {
    setFadeProp(prevFadeProp => ({ ...prevFadeProp, animate: false }));
    const id = setInterval(() => {
      setFadeProp(prevFadeProp => ({ ...prevFadeProp, animate: true }));
      clearInterval(id);
    }, 1500);
  });

  useEffect(() => {
    fadeIn.current();
  }, []);

  function fadeOut() {
    setFadeProp(prevFadeProp => ({ ...prevFadeProp, animate: true }));
  }

  return (
    <>
      <div id="helpDiv">
        <img
          src={process.env.PUBLIC_URL + '/images/help.gif'}
          className={fadeProp.animate ? 'fade-out' : 'fade-in'}
          alt="logo"
          onClick={fadeOut}
        ></img>
      </div>
    </>
  );
};

export default Fader;
