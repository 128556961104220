import React, { useEffect, useState } from 'react';
import Video from './Video';
import useScreenDrag from '../common/hooks/screenDragHook';
import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveView,
  selectSortedVideoIds,
  selectActiveVideoId,
} from '../store/screenSlice';

function Screen() {

  const [screenRef, activeView] = useScreenDrag();
  const dispatch = useDispatch();
  const isIOS = navigator.userAgent.match(/iPad|iPhone|iPod/);

  useEffect(() => {
    dispatch(setActiveView(activeView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  const sortedVideoIds = useSelector(selectSortedVideoIds);
  const activeVideoId = useSelector(selectActiveVideoId);
  const [loadedCount, setLoadedCount] = useState(10);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= (scrollHeight-2)) {
      //if (scrollTop + clientHeight === scrollHeight) {
      setLoadedCount(loadedCount + 1);
    }
  };
  
  return (     
    <div>
      <div className="Screen" ref={screenRef} onScroll={handleScroll}>
        {sortedVideoIds.map((id, index) => {
          if (index >= loadedCount) {
            return null;
          }

          if(isIOS && index > 9) {
            return <Video isMuted={true} key={id} id={id} active={id === activeVideoId} />;
          } else {
            return <Video isMuted={false} key={id} id={id} active={id === activeVideoId} />;
          }

          //return <Video isMuted={isMuted} key={id} id={id} active={id === activeVideoId} />;
        })}
      </div>
    </div>
  );
  
}

export default Screen;